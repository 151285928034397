import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from "jquery";
import "select2/dist/css/select2.min.css";
import "select2/dist/js/select2.min.js";
import axios from "axios";
import { toast } from "react-toastify";
import api, { baseURL } from '../../../API/api.url';
import Sidebar from "../components/Sidebar";
import BundledEditor from "../../admin/bundled";
import MultiSelectDropdown from "../../admin/components/MultiSelectDropdown";

const QuotationEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const quotationId = location.state;
    const [formErrors, setFormErrors] = useState({});
    const [quotationNo, setQuotationNo] = useState("");
    const [quotationDate, setQuotationDate] = useState("");
    const [items, setItems] = useState([{ description: "", qty: 1, price: "", total: 0 }]);
    const [milestones, setMilestones] = useState([]);
    const [selectedMilestones, setSelectedMilestones] = useState([]);
    const [leads, setLeads] = useState([]);
    const [filteredLeads, setFilteredLeads] = useState([]);
    const [selectedProjectType, setSelectedProjectType] = useState("");
    const [selectedLead, setSelectedLead] = useState(null);
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [quotationData, setQuotationData] = useState({});
    const [projectOverview, setProjectOverview] = useState("");
    const [projectDetails, setProjectDetails] = useState("");
    const [imagePreview, setImagePreview] = useState("");

    // Fetch states
    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/leads/states/IN`);
                setStateData(response.data.data);
            } catch (error) {
                console.error("Error fetching states:", error);
            }
        };
        fetchStates();
    }, []);

    // Fetch quotation details
    useEffect(() => {
        const fetchQuotationDetails = async () => {
            if (!quotationId) {
                toast.error("No quotation ID provided!");
                console.error("Quotation ID is undefined or null:", quotationId);
                return;
            }

            try {
                console.log("Fetching quotation with ID:", quotationId);
                const response = await axios.get(`${baseURL}${api.quotation.EditQuotation.url.replace(':id', quotationId)}`);
                console.log("API Response:", response);

                if (response.status === 200) {
                    const data = response.data.data;
                    console.log("Fetched quotation data:", data);
                    setQuotationData(data);
                    setQuotationNo(data.quotationNo || "");
                    setQuotationDate(data.quotationDate ? new Date(data.quotationDate).toISOString().split('T')[0] : "");
                    setItems(data.items || [{ description: "", qty: 1, price: "", total: 0 }]);
                    const preSelectedMilestones = data.milestone?.map((m) => ({
                        value: m.value,
                        label: `${m.name} ........... ${m.percentage}%`,
                    })) || [];
                    setSelectedMilestones(preSelectedMilestones);
                    setSelectedProjectType(data.projectType || "");
                    setProjectOverview(data.projectOverview || "");
                    setProjectDetails(data.projectDetails || "");
                    setImagePreview(data.image ? `${baseURL}${data.image}` : "");

                    // Handle lead pre-filling
                    if (data.lead) {
                        if (typeof data.lead === "object") {
                            setSelectedLead(data.lead);
                            setQuotationData({
                                ...data,
                                name: data.lead.name || data.name || "",
                                company: data.lead.company || data.company || "",
                                email: data.lead.email || data.email || "",
                                phone: data.lead.phone || data.phone || "",
                                address: data.lead.address || data.address || "",
                                state: data.lead.state || data.state || "",
                                city: data.lead.city || data.city || "",
                            });
                            if (data.lead.state) fetchCities(data.lead.state);
                        } else {
                            // Find lead from existing leads array
                            const leadData = leads.find((lead) => lead._id === data.lead);
                            if (leadData) {
                                setSelectedLead(leadData);
                                setQuotationData({
                                    ...data,
                                    name: leadData.name || data.name || "",
                                    company: leadData.company || data.company || "",
                                    email: leadData.email || data.email || "",
                                    phone: leadData.phone || data.phone || "",
                                    address: leadData.address || data.address || "",
                                    state: leadData.state || data.state || "",
                                    city: leadData.city || data.city || "",
                                });
                                if (leadData.state) fetchCities(leadData.state);
                            } else {
                                console.warn("Lead not found in existing leads array:", data.lead);
                                // Fallback to minimal data if lead isn't in leads yet
                                setQuotationData({
                                    ...data,
                                    name: data.name || "",
                                    company: data.company || "",
                                    email: data.email || "",
                                    phone: data.phone || "",
                                    address: data.address || "",
                                    state: data.state || "",
                                    city: data.city || "",
                                });
                            }
                        }
                    }

                    // Filter leads based on project type
                    if (data.projectType && leads.length > 0) {
                        const filtered = leads.filter((lead) => lead.projectType === data.projectType);
                        setFilteredLeads(filtered);
                    }
                } else {
                    toast.error("Quotation not found!");
                    console.error("Unexpected status code:", response.status);
                }
            } catch (error) {
                console.error("Error fetching quotation details:", error.message, error.response);
                toast.error("Something went wrong while fetching quotation details: " + (error.response?.data?.message || error.message));
            }
        };

        fetchQuotationDetails();
    }, [quotationId, leads]);

    // Fetch milestones and leads
    useEffect(() => {
        const fetchMilestones = async () => {
            try {
                const response = await fetch(`${baseURL}${api.milestone.milestoneGet.url}`);
                const data = await response.json();
                const milestoneOptions = data.map((milestone) => ({
                    value: milestone.value,
                    label: `${milestone.name} ........... ${milestone.percentage}%`,
                }));
                setMilestones(milestoneOptions);
                console.log("Fetched milestone options:", milestoneOptions);
            } catch (error) {
                console.error("Error fetching milestones:", error);
            }
        };

        const fetchLeads = async () => {
            try {
                const response = await axios.get(`${baseURL}${api.lead.getLeads.url}`);
                setLeads(response.data.data);
                setFilteredLeads(response.data.data);
            } catch (error) {
                console.error("Error fetching leads:", error);
            }
        };

        fetchMilestones();
        fetchLeads();
    }, []);

    // Initialize Select2 for leads dropdown
    useEffect(() => {
        $("#lead").select2();
        $("#lead").on("select2:select", function (e) {
            const selectedId = e.params.data.id;
            const lead = leads.find((l) => l._id === selectedId);
            setSelectedLead(lead || null);
            setQuotationData({
                ...quotationData,
                name: lead?.name || "",
                company: lead?.company || "",
                email: lead?.email || "",
                phone: lead?.phone || "",
                address: lead?.address || "",
                state: lead?.state || "",
                city: lead?.city || "",
            });
            if (lead?.state) fetchCities(lead.state);
        });

        return () => {
            $("#lead").select2("destroy");
        };
    }, [leads, filteredLeads, quotationData]);

    const fetchCities = async (stateName) => {
        try {
            const cityResponse = await axios.get(`${baseURL}/api/leads/cities/${stateName}/IN`);
            setCityData(cityResponse.data.data);
        } catch (error) {
            console.error("Error fetching cities:", error);
        }
    };

    const handleProjectTypeChange = (selectedType) => {
        setSelectedProjectType(selectedType);
        if (selectedType) {
            const filtered = leads.filter((lead) => lead.projectType === selectedType);
            setFilteredLeads(filtered);
        } else {
            setFilteredLeads(leads);
        }
        setSelectedLead(null);
        setQuotationData({
            ...quotationData,
            name: "",
            company: "",
            email: "",
            phone: "",
            address: "",
            state: "",
            city: "",
        });
    };

    const validateForm = () => {
        const errors = {};
        if (!quotationDate) errors.quotationDate = "Quotation Date is required";
        if (!selectedLead && !quotationData.lead) errors.lead = "Please select lead";
        const phoneRegex = /^\d{10}$/;
        if (!quotationData.phone) {
            errors.phone = "Phone is required";
        } else if (!phoneRegex.test(quotationData.phone)) {
            errors.phone = "Please enter a valid phone";
        }
        if (!quotationData.name && !quotationData.company) {
            errors.name = "Either Name or Company is required";
            errors.company = "Either Name or Company is required";
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleItemChange = (index, field, value) => {
        const updatedItems = [...items];
        updatedItems[index][field] = value;
        if (field === "qty" || field === "price") {
            updatedItems[index].total = updatedItems[index].qty * updatedItems[index].price;
        }
        setItems(updatedItems);
    };

    const addItem = () => {
        setItems([...items, { description: "", qty: 1, price: "", total: 0 }]);
    };

    const removeItem = (index) => {
        const updatedItems = items.filter((_, i) => i !== index);
        setItems(updatedItems);
    };

    const getTotalAmount = () => {
        return items.reduce((sum, item) => sum + (item.total || 0), 0);
    };

    const handleChange = (e) => {
        setQuotationData({ ...quotationData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validateForm()) {
            const formData = new FormData();
            formData.append("quotationNo", quotationNo);
            formData.append("quotationDate", quotationDate);
            formData.append("lead", selectedLead?._id || quotationData.lead);
            formData.append("name", quotationData.name || "");
            formData.append("company", quotationData.company || "");
            formData.append("email", quotationData.email || "");
            formData.append("phone", quotationData.phone || "");
            formData.append("address", quotationData.address || "");
            formData.append("state", quotationData.state || "");
            formData.append("city", quotationData.city || "");
            formData.append("projectType", selectedProjectType);
            formData.append("projectOverview", projectOverview);
            formData.append("projectDetails", projectDetails);
            formData.append("totalAmount", getTotalAmount());

            const imageFile = document.getElementById("image").files[0];
            if (imageFile) {
                formData.append("image", imageFile);
            }

            items.forEach((item, index) => {
                formData.append(`items[${index}][description]`, item.description);
                formData.append(`items[${index}][qty]`, item.qty);
                formData.append(`items[${index}][price]`, item.price);
                formData.append(`items[${index}][total]`, item.total);
            });

            formData.append("milestone", JSON.stringify(selectedMilestones));

            try {
                const response = await axios.put(
                    `${baseURL}${api.quotation.updateQuotation.url.replace(':id', quotationId)}`,
                    formData,
                    {
                        headers: { "Content-Type": "multipart/form-data" }
                    }
                );
                if (response.status === 200) {
                    toast.success("Quotation updated successfully!");
                    navigate("/quotation-list");
                } else {
                    toast.error("Failed to update quotation!");
                }
            } catch (error) {
                toast.error(error.response?.data?.message || "Error updating quotation!");
            }
        }
    };

    const handleLeadChange = (event) => {
        const selectedId = event.target.value;
        const lead = leads.find((l) => l._id === selectedId);
        setSelectedLead(lead || null);
        setQuotationData({
            ...quotationData,
            name: lead?.name || "",
            company: lead?.company || "",
            email: lead?.email || "",
            phone: lead?.phone || "",
            address: lead?.address || "",
            state: lead?.state || "",
            city: lead?.city || "",
        });
        if (lead?.state) fetchCities(lead.state);
    };

    return (
        <div className="admin d-flex">
            <Sidebar />
            <div className="main-content container-fluid d-flex justify-content-center align-items-center">
                <div className="p-4 w-100">
                    <div className="card-body">
                        <h3 className="text-center mb-4">Edit Quotation</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="row g-3">
                                <div className="col-md-4">
                                    <label htmlFor="quotationNo" className="form-label">Quotation No</label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        name="quotationNo" 
                                        id="quotationNo" 
                                        value={quotationNo} 
                                        onChange={(e) => setQuotationNo(e.target.value)} 
                                        readOnly 
                                    />
                                    {formErrors.quotationNo && <small className="text-danger">{formErrors.quotationNo}</small>}
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="quotationDate" className="form-label">Quotation Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="quotationDate"
                                        id="quotationDate"
                                        value={quotationDate}
                                        onChange={(e) => setQuotationDate(e.target.value)}
                                    />
                                    {formErrors.quotationDate && <small className="text-danger">{formErrors.quotationDate}</small>}
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="image" className="form-label">Image</label>
                                    <input 
                                        type="file" 
                                        className="form-control" 
                                        name="image" 
                                        id="image" 
                                        onChange={(e) => setImagePreview(URL.createObjectURL(e.target.files[0]))} 
                                    />
                                    {imagePreview && <img src={imagePreview} alt="Quotation" style={{ width: '100px', height: '100px', marginTop: '10px' }} />}
                                </div>
                            </div>

                            <div className="row g-3 mt-3">
                                <div className="col-md-4">
                                    <label htmlFor="projectType" className="form-label">Project Type</label>
                                    <select 
                                        className="form-control" 
                                        name="projectType" 
                                        id="projectType" 
                                        value={selectedProjectType} 
                                        onChange={(e) => handleProjectTypeChange(e.target.value)}
                                    >
                                        <option value="">Select Project Type</option>
                                        <option value="Web Development">Web Development</option>
                                        <option value="App Development">App Development</option>
                                        <option value="UI/UX Design">UI/UX Design</option>
                                        <option value="Software Development">Software Development</option>
                                        <option value="Game Development">Game Development</option>
                                        <option value="Data Science">Data Science</option>
                                        <option value="Machine Learning">Machine Learning</option>
                                        <option value="Cybersecurity">Cybersecurity</option>
                                        <option value="Blockchain">Blockchain</option>
                                        <option value="Cloud Computing">Cloud Computing</option>
                                        <option value="DevOps">DevOps</option>
                                        <option value="Digital Marketing">Digital Marketing</option>
                                        <option value="SEO Optimization">SEO Optimization</option>
                                        <option value="Graphic Design">Graphic Design</option>
                                        <option value="Video Editing">Video Editing</option>
                                        <option value="Content Writing">Content Writing</option>
                                        <option value="Artificial Intelligence">Artificial Intelligence</option>
                                        <option value="IoT (Internet of Things)">IoT (Internet of Things)</option>
                                        <option value="Embedded Systems">Embedded Systems</option>
                                        <option value="Networking">Networking</option>
                                        <option value="Database Administration">Database Administration</option>
                                        <option value="Robotics">Robotics</option>
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="lead" className="form-label">Select Lead</label>
                                    <select 
                                        className="form-control select2" 
                                        name="lead" 
                                        id="lead" 
                                        value={selectedLead?._id || quotationData.lead || ""} 
                                        onChange={handleLeadChange}
                                    >
                                        <option value="">Select Lead</option>
                                        {filteredLeads.map((lead) => (
                                            <option key={lead._id} value={lead._id}>
                                                {lead.name} ({lead.company}) ({lead.phone})
                                            </option>
                                        ))}
                                    </select>
                                    {formErrors.lead && <small className="text-danger">{formErrors.lead}</small>}
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="name" 
                                        id="name" 
                                        value={quotationData.name || ""} 
                                        onChange={handleChange} 
                                    />
                                    {formErrors.name && <small className="text-danger">{formErrors.name}</small>}
                                </div>
                            </div>

                            <div className="row g-3 mt-3">
                                <div className="col-md-4">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input 
                                        type="email" 
                                        className="form-control" 
                                        name="email" 
                                        id="email" 
                                        value={quotationData.email || ""} 
                                        onChange={handleChange} 
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="phone" className="form-label">Phone</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="phone" 
                                        id="phone" 
                                        value={quotationData.phone || ""} 
                                        onChange={handleChange} 
                                    />
                                    {formErrors.phone && <small className="text-danger">{formErrors.phone}</small>}
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="company" className="form-label">Company</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="company" 
                                        id="company" 
                                        value={quotationData.company || ""} 
                                        onChange={handleChange} 
                                    />
                                    {formErrors.company && <small className="text-danger">{formErrors.company}</small>}
                                </div>
                            </div>

                            <div className="row g-3 mt-3">
                                <div className="col-md-4">
                                    <label htmlFor="address" className="form-label">Address</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="address" 
                                        id="address" 
                                        value={quotationData.address || ""} 
                                        onChange={handleChange} 
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="state" className="form-label">State</label>
                                    <select 
                                        className="form-control" 
                                        name="state" 
                                        id="state" 
                                        value={quotationData.state || ""} 
                                        onChange={(e) => {
                                            handleChange(e);
                                            fetchCities(e.target.value);
                                        }}
                                    >
                                        <option value="">Select State</option>
                                        {stateData.map((state, index) => (
                                            <option key={index} value={state.isoCode}>{state.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="city" className="form-label">City</label>
                                    <select 
                                        className="form-control" 
                                        name="city" 
                                        id="city" 
                                        value={quotationData.city || ""} 
                                        onChange={handleChange}
                                    >
                                        <option value="">Select City</option>
                                        {cityData.map((city, index) => (
                                            <option key={index} value={city.name}>{city.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="mt-4">
                                <table className="table table-bordered">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Description</th>
                                            <th>Qty</th>
                                            <th>Price</th>
                                            <th>Total</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Description"
                                                        value={item.description}
                                                        onChange={(e) => handleItemChange(index, "description", e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Qty"
                                                        value={item.qty}
                                                        onChange={(e) => handleItemChange(index, "qty", Number(e.target.value))}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Price"
                                                        value={item.price}
                                                        onChange={(e) => handleItemChange(index, "price", Number(e.target.value))}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Total"
                                                        value={item.total}
                                                        readOnly
                                                    />
                                                </td>
                                                <td>
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-danger" 
                                                        onClick={() => removeItem(index)}
                                                    >
                                                        Remove
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="3" className="text-end fw-bold">Total Amount:</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="totalAmount"
                                                    value={getTotalAmount()}
                                                    readOnly
                                                />
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <button 
                                    type="button" 
                                    className="btn btn-secondary mt-2" 
                                    onClick={addItem}
                                >
                                    Add Item
                                </button>
                            </div>

                            <div className="mt-4">
                                <label>Project Overview</label>
                                <BundledEditor
                                    id="projectOverview"
                                    init={{
                                        height: 400,
                                        menubar: false,
                                        plugins: ["advlist", "autolink", "link", "lists", "preview", "image", "media"],
                                        toolbar: "undo redo | bold italic underline | alignleft aligncenter alignright | bullist numlist | image media preview",
                                    }}
                                    value={projectOverview}
                                    onEditorChange={(content) => setProjectOverview(content)}
                                />
                            </div>

                            <div className="mt-4">
                                <label>Project Details</label>
                                <BundledEditor
                                    id="projectDetails"
                                    init={{
                                        height: 400,
                                        menubar: false,
                                        plugins: ["advlist", "autolink", "link", "lists", "preview", "image", "media"],
                                        toolbar: "undo redo | bold italic underline | alignleft aligncenter alignright | bullist numlist | image media preview",
                                    }}
                                    value={projectDetails}
                                    onEditorChange={(content) => setProjectDetails(content)}
                                />
                            </div>

                            <div className="mt-4">
                                <MultiSelectDropdown
                                    options={milestones}
                                    label="Project Milestone Breakdown"
                                    onChange={setSelectedMilestones}
                                    totalAmount={getTotalAmount()}
                                    value={selectedMilestones}
                                />
                            </div>

                            <button 
                                type="submit" 
                                className="btn btn-primary w-100 mt-4" 
                                style={{ fontSize: "1.2rem", padding: "12px" }}
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuotationEdit;