import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import './ServicePage.css'; // Import external CSS file
import { toast } from "react-toastify";
import { baseURL } from '../../API/api.url';
import BundledEditor from './bundled'; // Import BundledEditor (TinyMCE)

function ServicePage() {
  const [services, setServices] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    slug: "",
    description: "",
    thumbnail: "",
    content: "",
    coverImage: "",
    seometa: "",
    previewImage: "",
    thumbnailFile: null,
    coverImageFile: null,
    previewImageFile: null,
    thumbnailPreview: null,
    coverImagePreview: null,
    previewImagePreview: null,
  });
  const [dynamicFields, setDynamicFields] = useState([]);
  const [technologyFields, setTechnologyFields] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editServiceId, setEditServiceId] = useState(null);
  const editorRef = useRef(null);

  // Fetch Services
  useEffect(() => {
    axios
      .get("https://ubikon.in/api/services/all")
      .then((res) => setServices(res.data))
      .catch((err) => console.error("Error fetching services:", err));
  }, []);

  // Handle Add Dynamic Field
  const addDynamicField = () => {
    setDynamicFields([...dynamicFields, { heading: "", value: "" }]);
  };

  // Handle Change in Dynamic Fields
  const handleDynamicFieldChange = (index, field, value) => {
    const updatedFields = [...dynamicFields];
    updatedFields[index][field] = value;
    setDynamicFields(updatedFields);
  };

  // Handle Remove Dynamic Field
  const removeDynamicField = (index) => {
    setDynamicFields(dynamicFields.filter((_, i) => i !== index));
  };

  // Handle Add Technology Field
  const addTechnologyField = () => {
    setTechnologyFields([...technologyFields, { title: "", description: "", technologyImage: null }]);
  };

  // Handle Change in Technology Fields
  const handleTechnologyFieldChange = (index, field, value) => {
    const updatedFields = [...technologyFields];
    updatedFields[index][field] = value;
    setTechnologyFields(updatedFields);
  };

  // Handle Technology Image Change
  const handleTechnologyImageChange = (index, file) => {
    const updatedFields = [...technologyFields];
    updatedFields[index].technologyImage = file;
    setTechnologyFields(updatedFields);
  };

  // Handle Remove Technology Field
  const removeTechnologyField = (index) => {
    setTechnologyFields(technologyFields.filter((_, i) => i !== index));
  };

  // Handle Thumbnail Change
  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        thumbnailFile: file,
        thumbnailPreview: URL.createObjectURL(file),
      });
    }
  };

  // Handle Cover Image Change
  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        coverImageFile: file,
        coverImagePreview: URL.createObjectURL(file),
      });
    }
  };

  // Handle Preview Image Change
  const handlePreviewImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        previewImageFile: file,
        previewImagePreview: URL.createObjectURL(file),
      });
    }
  };

  // Handle Add or Edit Service
  const handleAddOrEditService = async (e) => {
    e.preventDefault();
    const form = new FormData();
  
    form.append("title", formData.title);
    form.append("slug", formData.slug);
    form.append("description", formData.description);
    form.append("seometa", formData.seometa);
    form.append("content", formData.content);
    if (formData.thumbnailFile) form.append("thumbnail", formData.thumbnailFile);
    if (formData.coverImageFile) form.append("coverImage", formData.coverImageFile);
    if (formData.previewImageFile) form.append("previewImage", formData.previewImageFile);
    form.append("dynamicFields", JSON.stringify(dynamicFields));
    form.append("technology", JSON.stringify(technologyFields.map(({ technologyImage, ...rest }) => rest)));
  
    technologyFields.forEach((field) => {
      if (field.technologyImage && field.technologyImage instanceof File) {
        form.append("technologyImage", field.technologyImage);
      }
    });
  
    try {
      if (editServiceId) {
        const response = await axios.put(
          `https://ubikon.in/api/services/update/${editServiceId}`,
          form,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        setServices(services.map((service) => (service._id === editServiceId ? response.data : service)));
        toast.success("Service updated successfully!");
      } else {
        const response = await axios.post(
          "https://ubikon.in/api/services/create",
          form,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        setServices([...services, response.data]);
        toast.success("Service created successfully!");
      }
      setShowForm(false);
      setEditServiceId(null);
      setFormData({
        title: "",
        slug: "",
        description: "",
        thumbnail: "",
        content: "",
        coverImage: "",
        seometa: "",
        previewImage: "",
        thumbnailFile: null,
        coverImageFile: null,
        previewImageFile: null,
        thumbnailPreview: null,
        coverImagePreview: null,
        previewImagePreview: null,
      });
      setDynamicFields([]);
      setTechnologyFields([]);
    } catch (error) {
      console.error("Error:", error.response?.data || "Something went wrong!");
      toast.error("Error: " + (error.response?.data?.message || "Something went wrong!"));
    }
  };

  // Handle Edit
  const handleEdit = (service) => {
    console.log("Editing service:", service);
    console.log("Technology data:", service.technology);

    setEditServiceId(service._id);
    setFormData({
      title: service.title,
      slug: service.slug,
      description: service.description,
      seometa: service.seometa,
      thumbnail: service.thumbnail,
      content: service.content,
      coverImage: service.coverImage,
      previewImage: service.previewImage,
      thumbnailFile: null,
      thumbnailPreview: `${baseURL}${service.thumbnail}`,
      coverImageFile: null,
      coverImagePreview: `${baseURL}${service.coverImage}`,
      previewImageFile: null,
      previewImagePreview: `${baseURL}${service.previewImage}`,
    });
    setDynamicFields(service.dynamicFields || []);
    const techFields = service.technology.map((field) => {
      const techImage = field.technologyImage ? `${baseURL}${field.technologyImage}` : null;
      console.log(`Technology image for ${field.title}:`, techImage);
      return {
        title: field.title || "",
        description: field.description || "",
        technologyImage: techImage,
      };
    });
    setTechnologyFields(techFields || []);
    setShowForm(true);
  };

  // Handle Delete
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://ubikon.in/api/services/delete/${id}`);
      setServices(services.filter((service) => service._id !== id));
      toast.success("Service deleted successfully!");
    } catch (error) {
      toast.error("Error deleting data: " + (error.response?.data?.message || "Something went wrong!"));
    }
  };

  return (
    <div className="container">
      <button onClick={() => setShowForm(true)} className="btn btn-primary mb-3">
        ➕ Add Category
      </button>

      {services.length === 0 ? (
        <p>Loading...</p>
      ) : (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Title</th>
              <th>Slug</th>
              <th>SEO Meta</th>
              <th>Image</th>
              <th>Published Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {services.map((service) => (
              <tr key={service._id}>
                <td>{service.title}</td>
                <td>{service.slug}</td>
                <td>{service.seometa}</td>
                <td>
                  <img src={`${baseURL}${service.thumbnail}`} alt="Thumbnail" className="img-thumbnail" width="50" />
                  <img src={`${baseURL}${service.coverImage}`} alt="Cover" className="img-thumbnail" width="50" />
                </td>
                <td>{new Date(service.createdAt).toLocaleDateString()}</td>
                <td>
                  <button onClick={() => handleEdit(service)} className="btn btn-warning">✏️ Edit</button>
                  <button onClick={() => handleDelete(service._id)} className="btn btn-danger">❌ Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {showForm && (
        <div className="servicepage-form-overlay">
          <div className="servicepage-form-content">
            <button
              type="button"
              className="servicepage-btn-close"
              onClick={() => setShowForm(false)}
              aria-label="Close"
            ></button>

            <h2>{editServiceId ? "Edit Category" : "Add Category"}</h2>

            <form onSubmit={handleAddOrEditService}>
              <div className="mb-3">
                <input
                  type="text"
                  placeholder="Enter Service Title"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  required
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <input
                  type="text"
                  placeholder="Enter Slug"
                  value={formData.slug}
                  onChange={(e) => setFormData({ ...formData, slug: e.target.value })}
                  required
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <textarea
                  placeholder="Enter Description"
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  required
                  className="form-control"
                ></textarea>
              </div>

              <div className="mb-3">
                <textarea
                  placeholder="Enter SEO Meta"
                  value={formData.seometa}
                  onChange={(e) => setFormData({ ...formData, seometa: e.target.value })}
                  required
                  className="form-control"
                ></textarea>
              </div>

              <h6>Add Dynamic Fields</h6>
              {dynamicFields.map((field, index) => (
                <div key={index} className="input-group mb-3">
                  <input
                    type="text"
                    placeholder="Heading"
                    value={field.heading}
                    onChange={(e) => handleDynamicFieldChange(index, "heading", e.target.value)}
                    className="form-control"
                  />
                  <input
                    type="text"
                    placeholder="Value"
                    value={field.value}
                    onChange={(e) => handleDynamicFieldChange(index, "value", e.target.value)}
                    className="form-control"
                  />
                  <button type="button" className="btn btn-danger" onClick={() => removeDynamicField(index)}>
                    ❌
                  </button>
                </div>
              ))}
              <button type="button" className="btn btn-primary mb-3" onClick={addDynamicField}>
                ➕ Add More
              </button>

              <h6>Add Technology Fields</h6>
              {technologyFields.map((field, index) => (
                <div key={index} className="input-group mb-3">
                  <input
                    type="text"
                    placeholder="Title"
                    value={field.title}
                    onChange={(e) => handleTechnologyFieldChange(index, "title", e.target.value)}
                    className="form-control"
                  />
                  <input
                    type="text"
                    placeholder="Description"
                    value={field.description}
                    onChange={(e) => handleTechnologyFieldChange(index, "description", e.target.value)}
                    className="form-control"
                  />
                  <input
                    type="file"
                    onChange={(e) => handleTechnologyImageChange(index, e.target.files[0])}
                    className="form-control"
                  />
                  {field.technologyImage && (
                    <img
                      src={
                        field.technologyImage instanceof File
                          ? URL.createObjectURL(field.technologyImage)
                          : field.technologyImage // Existing image URL from server
                      }
                      alt="Technology Preview"
                      className="img-thumbnail mt-2"
                      width="50"
                      onError={(e) => console.error(`Image failed to load: ${field.technologyImage}`)}
                    />
                  )}
                  <button type="button" className="btn btn-danger" onClick={() => removeTechnologyField(index)}>
                    ❌
                  </button>
                </div>
              ))}
              <button type="button" className="btn btn-primary mb-3" onClick={addTechnologyField}>
                ➕ Add More
              </button>

              <div className="mb-3">
                <label className="form-label">Thumbnail:</label>
                <input type="file" onChange={handleThumbnailChange} className="form-control" />
                {formData.thumbnailPreview && (
                  <img
                    src={formData.thumbnailPreview}
                    alt="Thumbnail Preview"
                    className="img-thumbnail mt-2"
                    width="150"
                  />
                )}
              </div>

              <div className="mb-3">
                <label className="form-label">Cover Image:</label>
                <input type="file" onChange={handleCoverImageChange} className="form-control" />
                {formData.coverImagePreview && (
                  <img
                    src={formData.coverImagePreview}
                    alt="Cover Preview"
                    className="img-thumbnail mt-2"
                    width="150"
                  />
                )}
              </div>

              <div className="mb-3">
                <label className="form-label">Preview Image:</label>
                <input type="file" onChange={handlePreviewImageChange} className="form-control" />
                {formData.previewImagePreview && (
                  <img
                    src={formData.previewImagePreview}
                    alt="Preview Image"
                    className="img-thumbnail mt-2"
                    width="150"
                  />
                )}
              </div>

              <div className="form-group">
                <label>Content</label>
                <BundledEditor
                  onInit={(_evt, editor) => (editorRef.current = editor)}
                  initialValue={formData.content}
                  init={{
                    height: 0.5 * window.innerHeight,
                    width: 0.75 * window.innerWidth,
                    menubar: false,
                    plugins: [
                      "advlist",
                      "anchor",
                      "autolink",
                      "link",
                      "lists",
                      "searchreplace",
                      "table",
                      "wordcount",
                      "code",
                      "directionality",
                      "media",
                      "preview",
                      "image",
                      "emoticons",
                    ],
                    toolbar:
                      "undo redo | blocks | " +
                      "bold italic underline forecolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "code directionality media table preview image emoticons",
                    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                  onChange={() => {
                    if (editorRef.current) {
                      setFormData({ ...formData, content: editorRef.current.getContent() });
                    }
                  }}
                />
              </div>

              <button type="submit" className="btn btn-success">
                {editServiceId ? "✅ Update Service" : "✅ Save Service"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ServicePage;
