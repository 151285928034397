import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { FaTimes } from "react-icons/fa"; // Import close icon from react-icons
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import BundledEditor from './bundled'; // Import BundledEditor (TinyMCE)

const baseURL = "https://ubikon.in";

const SubService = () => {
  const [formData, setFormData] = useState({
    title: "",
    slug: "",
    serviceId: "",
    description: "",
    thumbnail: null,
    content: "",
    seometa: "",
    publishedAt: "",
    features: [{ title: "", description: "", featuresIcon: null }],
    useCases: [{ title: "", description: "", useCasesIcon: null }],
    whyChooseUs: [{ title: "", description: "", whyChooseUsIcon: null }],
  });
  const [services, setServices] = useState([]);
  const [subservices, setSubServices] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editSubserviceId, setEditSubserviceId] = useState(null);

  const editorRef = useRef(null);

  useEffect(() => {
    axios.get(`${baseURL}/api/services/all`)
      .then((response) => {
        setServices(response.data);
      })
      .catch((error) => console.error("Error fetching services", error));

    axios.get(`${baseURL}/api/sub-services/all`)
      .then((response) => {
        setSubServices(response.data);
      })
      .catch((error) => console.error("Error fetching sub-services", error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.files[0] });
  };

  const handleNestedFileChange = (index, e, field) => {
    const updatedField = [...formData[field]];
    updatedField[index][`${field}Icon`] = e.target.files[0];
    setFormData({ ...formData, [field]: updatedField });
  };

  const handleNestedChange = (index, e, field) => {
    const { name, value } = e.target;
    const updatedField = [...formData[field]];
    updatedField[index][name] = value;
    setFormData({ ...formData, [field]: updatedField });
  };

  const addNestedField = (field) => {
    setFormData({
      ...formData,
      [field]: [...formData[field], { title: "", description: "", [`${field}Icon`]: null }],
    });
  };

  const removeNestedField = (index, field) => {
    const updatedField = [...formData[field]];
    updatedField.splice(index, 1);
    setFormData({ ...formData, [field]: updatedField });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    Object.keys(formData).forEach((key) => {
      if (Array.isArray(formData[key])) {
        formDataToSend.append(key, JSON.stringify(formData[key]));
        formData[key].forEach((item, index) => {
          if (item[`${key}Icon`]) {
            formDataToSend.append(`${key}Icon`, item[`${key}Icon`]);
          }
        });
      } else {
        if (key === "thumbnail" && formData[key] instanceof File) {
          formDataToSend.append(key, formData[key]);
        } else {
          formDataToSend.append(key, formData[key]);
        }
      }
    });

    try {
      if (isEditMode) {
        await axios.put(`${baseURL}/api/sub-services/update/${editSubserviceId}`, formDataToSend, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        alert("Sub-service updated successfully!");
      } else {
        await axios.post(`${baseURL}/api/sub-services/create`, formDataToSend, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        alert("Sub-service added successfully!");
      }
      
      setIsFormVisible(false); // Close the form after successful submission
      setIsEditMode(false);
      setEditSubserviceId(null);
      // Fetch updated sub-services data
      const response = await axios.get(`${baseURL}/api/sub-services/all`);
      setSubServices(response.data);
    } catch (error) {
      console.error("Error submitting form", error);
      alert("Failed to submit sub-service");
    }
  };

  const handleEdit = (subservice) => {
    setFormData({
      title: subservice.title,
      slug: subservice.slug,
      serviceId: subservice.serviceId,
      description: subservice.description,
      thumbnail: subservice.thumbnail,
      content: subservice.content,
      seometa: subservice.seometa,
      publishedAt: subservice.publishedAt,
      features: subservice.features,
      useCases: subservice.useCases,
      whyChooseUs: subservice.whyChooseUs,
    });
    setIsFormVisible(true);
    setIsEditMode(true);
    setEditSubserviceId(subservice._id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${baseURL}/api/sub-services/delete/${id}`);
      alert("Sub-service deleted successfully!");
      // Fetch updated sub-services data
      const response = await axios.get(`${baseURL}/api/sub-services/all`);
      setSubServices(response.data);
    } catch (error) {
      console.error("Error deleting sub-service", error);
      alert("Failed to delete sub-service");
    }
  };

  return (
    <div className="container mt-5">
      {!isFormVisible && (
        <button 
          className="btn btn-primary mb-4"
          onClick={() => {
            setIsFormVisible(true);
            setIsEditMode(false);
            setFormData({
              title: "",
              slug: "",
              serviceId: "",
              description: "",
              thumbnail: null,
              content: "",
              seometa: "",
              publishedAt: "",
              features: [{ title: "", description: "", featuresIcon: null }],
              useCases: [{ title: "", description: "", useCasesIcon: null }],
              whyChooseUs: [{ title: "", description: "", whyChooseUsIcon: null }],
            });
          }}
        >
          ➕ Add Sub-Service
        </button>
      )}

      {/* Form visible directly on the page */}
      {isFormVisible ? (
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h5>{isEditMode ? "Edit Sub-Service" : "Add Sub-Service"}</h5>
            <button 
              type="button" 
              className="close"
              onClick={() => setIsFormVisible(false)}
            >
              <span aria-hidden="true"><FaTimes /></span>
            </button>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row" >
                <div className="form-group col-md-3">
                  <label> -Title</label>
                  <input 
                    type="text" 
                    name="title" 
                    className="form-control" 
                    placeholder="Title" 
                    value={formData.title} 
                    onChange={handleChange} 
                    required 
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Slug</label>
                  <input 
                    type="text" 
                    name="slug" 
                    className="form-control" 
                    placeholder="Slug" 
                    value={formData.slug} 
                    onChange={handleChange} 
                    required 
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Service</label>
                  <select 
                    name="serviceId" 
                    className="form-control" 
                    value={formData.serviceId} 
                    onChange={handleChange} 
                    required
                  >
                    <option value="">Select Service</option>
                    {services.map((service) => (
                      <option key={service._id} value={service._id}>{service.title}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-3">
                  <label>Description</label>
                  <textarea 
                    name="description" 
                    className="form-control" 
                    placeholder="Description" 
                    value={formData.description} 
                    onChange={handleChange} 
                    required 
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-4">
                  <label>Thumbnail</label>
                  {formData.thumbnail && !(formData.thumbnail instanceof File) && (
                    <div className="mb-2">
                      <img src={`${baseURL}${formData.thumbnail}`} alt="Current Thumbnail" width={50} />
                    </div>
                  )}
                  <input 
                    type="file" 
                    className="form-control-file" 
                    onChange={(e) => handleFileChange(e, "thumbnail")} 
                  />
                </div>
              <div className="form-group col-md-4">
                <label>SEO Meta</label>
                <input 
                  type="text" 
                  name="seometa" 
                  className="form-control" 
                  placeholder="SEO Meta" 
                  value={formData.seometa} 
                  onChange={handleChange} 
                />
              </div>
              <div className="form-group col-md-4">
                <label>Published At</label>
                <input 
                  type="date" 
                  name="publishedAt" 
                  className="form-control" 
                  value={formData.publishedAt} 
                  onChange={handleChange} 
                />
              </div>
             </div>
              <div className="form-group">
                <label>Content</label>
                <BundledEditor
                  onInit={(_evt, editor) => (editorRef.current = editor)}
                  initialValue={formData.content}
                  init={{
                    height: 0.5 * window.innerHeight,
                    width: 0.75 * window.innerWidth,
                    menubar: false,
                    plugins: [
                      "advlist",
                      "anchor",
                      "autolink",
                      "link",
                      "lists",
                      "searchreplace",
                      "table",
                      "wordcount",
                      "code",
                      "directionality",
                      "media",
                      "preview",
                      "image",
                      "emoticons",
                    ],
                    toolbar:
                      "undo redo | blocks | " +
                      "bold italic underline forecolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "code directionality media table preview image emoticons",
                    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                  onChange={() => {
                    if (editorRef.current) {
                      setFormData({ ...formData, content: editorRef.current.getContent() });
                    }
                  }}
                />
              </div>
              
              <h3>Features</h3>
              
              {formData.features.map((feature, index) => (
                <div key={index} className="row">
                  <div className="form-group col-md-3">
                    <input 
                      type="text" 
                      name="title" 
                      className="form-control mb-2" 
                      placeholder="Feature Title" 
                      value={feature.title} 
                      onChange={(e) => handleNestedChange(index, e, "features")} 
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <input 
                      type="text" 
                      name="description" 
                      className="form-control mb-2" 
                      placeholder="Feature Description" 
                      value={feature.description} 
                      onChange={(e) => handleNestedChange(index, e, "features")} 
                    />
                  </div>
                  <div className="form-group col-md-3">
                    {feature.featuresIcon && (
                      <div className="mb-2">
                        <img src={`${baseURL}${feature.featuresIcon}`} alt="Current Feature Icon" width={50} />
                      </div>
                    )}
                    <div className="form-group col-md-3">
                    <input 
                      type="file" 
                      className="form-control-file mb-3" 
                      onChange={(e) => handleNestedFileChange(index, e, "features")} 
                    />
                    </div>
                  </div>
                  <button 
                    type="button" 
                    className="btn btn-danger"
                    onClick={() => removeNestedField(index, "features")}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button 
                type="button" 
                className="btn btn-secondary mb-3" 
                onClick={() => addNestedField("features")}
              >
                Add Feature
              </button>

              <h3>Use Cases</h3>
              {formData.useCases.map((useCase, index) => (
                <div key={index} className="form-row mb-3">
                  <div className="form-group col-md-4">
                    <input 
                      type="text" 
                      name="title" 
                      className="form-control mb-2" 
                      placeholder="Use Case Title" 
                      value={useCase.title} 
                      onChange={(e) => handleNestedChange(index, e, "useCases")} 
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <input 
                      type="text" 
                      name="description" 
                      className="form-control mb-2" 
                      placeholder="Use Case Description" 
                      value={useCase.description} 
                      onChange={(e) => handleNestedChange(index, e, "useCases")} 
                    />
                  </div>
                  <div className="form-group col-md-4">
                    {useCase.useCasesIcon && (
                      <div className="mb-2">
                        <img src={`${baseURL}${useCase.useCasesIcon}`} alt="Current Use Case Icon" width={50} />
                      </div>
                    )}
                    <input 
                      type="file" 
                      className="form-control-file mb-2" 
                      onChange={(e) => handleNestedFileChange(index, e, "useCases")} 
                    />
                  </div>
                  <button 
                    type="button" 
                    className="btn btn-danger"
                    onClick={() => removeNestedField(index, "useCases")}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button 
                type="button" 
                className="btn btn-secondary mb-3" 
                onClick={() => addNestedField("useCases")}
              >
                Add Use Case
              </button>

              <h3>Why Choose Us</h3>
              {formData.whyChooseUs.map((why, index) => (
                <div key={index} className="form-row mb-3">
                  <div className="form-group col-md-4">
                    <input 
                      type="text" 
                      name="title" 
                      className="form-control mb-2" 
                      placeholder="Why Choose Us Title" 
                      value={why.title} 
                      onChange={(e) => handleNestedChange(index, e, "whyChooseUs")} 
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <input 
                      type="text" 
                      name="description" 
                      className="form-control mb-2" 
                      placeholder="Why Choose Us Description" 
                      value={why.description} 
                      onChange={(e) => handleNestedChange(index, e, "whyChooseUs")} 
                    />
                  </div>
                  <div className="form-group col-md-4">
                    {why.whyChooseUsIcon && (
                      <div className="mb-2">
                        <img src={`${baseURL}${why.whyChooseUsIcon}`} alt="Current Why Choose Us Icon" width={50} />
                      </div>
                    )}
                    <input 
                      type="file" 
                      className="form-control-file mb-2" 
                      onChange={(e) => handleNestedFileChange(index, e, "whyChooseUs")} 
                    />
                  </div>
                  <button 
                    type="button" 
                    className="btn btn-danger"
                    onClick={() => removeNestedField(index, "whyChooseUs")}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button 
                type="button" 
                className="btn btn-secondary mb-3" 
                onClick={() => addNestedField("whyChooseUs")}
              >
                Add Why Choose Us
              </button>

              <button type="submit" className="btn btn-primary">{isEditMode ? "Update" : "Submit"}</button>
            </form>
          </div>
        </div>
      ) : (
        <>
          {subservices.length === 0 ? (
            <p>Loading...</p>
          ) : (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Slug</th>
                  <th>Description</th>
                  <th>SEO Meta</th>
                  <th>Image</th>
                  <th>Published Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {subservices.map((subservice) => (
                  <tr key={subservice._id}>
                    <td>{subservice.title}</td>
                    <td>{subservice.slug}</td>
                    <td>{subservice.description}</td>
                    <td>{subservice.seometa}</td>
                    <td>
                      <img src={`${baseURL}${subservice.thumbnail}`} alt={subservice.title} width={50} />
                    </td>
                    <td>{new Date(subservice.createdAt).toLocaleDateString()}</td>
                    <td>
                      <button onClick={() => handleEdit(subservice)} className="btn btn-secondary btn-sm">Edit</button>
                      <button onClick={() => handleDelete(subservice._id)} className="btn btn-danger btn-sm">Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
};

export default SubService;